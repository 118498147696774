import type { CustomElement } from '@integrabeauty/custom-elements';
import * as ShopifyThemeCurrency from '@shopify/theme-currency';
import html from './index.html';
import styles from './index.scss';

/**
 * Accepts a price in cents and then renders it in the appropriate format.
 *
 * @todo rename data-cents to data-value, we do not work only with USD dollar subunit of cents, we
 * work with many kinds of currencies. The word cents is specific to USD/CAD.
 */
class PriceElement extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-aria-label-prefix',
      'data-cents',
      'data-negative',
      'data-currency-code',
      'data-currency-symbol',
      'data-hide'
    ];
  }

  readonly dataset!: {
    /**
     * When set the "aria-label" of the component will be set to `ariaLabelPrefix price`
     *
     * This allows to handle sales prices in an accessible way. The strikethrough HTML
     * element usually used to mark the original price vs sales price has very poor
     * accessibility support across browsers. To remediate this we can replace a price set
     * like `<s>$28</s> $20` with a `Original price: $28; Sales price: $20` using "aria-label"s
     * on both price elements.
     */
    ariaLabelPrefix: string;

    /**
     * The value to render in the subunit of the currency. Because we mostly work with USD, this
     * is referred to as cents.
     */
    cents: string;

    /**
     * The ISO code of the currency. All uppercase.
     *
     * @example "USD"
     */
    currencyCode: string;

    /**
     * The symbol of the currency.
     *
     * @example "$"
     */
    currencySymbol: string;

    /**
     * To show/hide the value.
     */
    hide: string;

    /**
     * Boolean value representing this numerical value represents a discount and therefor should be
     * preceded with "-" to visually indicate the value removed from the cart totals when applied.
     */
    negative: string;

    /**
     * If true will display the currency code even for USD.
     * If false will not display the currency code regardless of the currency.
     * The default (not set) is to show only the codes of currencies other than USD.
     */
    showCode: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const container = this.shadowRoot.querySelector<HTMLElement>('div');

    // TODO I don't think we should handle hiding/showing the element by the data-hide attribute.
    // It sets by a parent component so why not just add `.hidden` class name with `display: none`
    // or similar in the parent component?
    if (this.dataset.hide === 'true') {
      container.classList.add('hidden');
      container.removeAttribute('aria-label');
      container.removeAttribute('role');
      return;
    } else {
      container.classList.remove('hidden');
    }

    // For cases where there is no attribute, or there is an attribute and it is an empty string,
    // we do not hide the container, but we do ensure it has no text
    if (!this.dataset.cents || this.dataset.cents.trim() === '' ||
      this.dataset.cents === 'undefined' || this.dataset.cents === 'null' ||
      !this.dataset.currencyCode || !this.dataset.currencySymbol) {
      container.textContent = '';
      container.removeAttribute('aria-label');
      container.removeAttribute('role');
      return;
    }

    const value = parseInt(this.dataset.cents, 10);
    const hasDecimal = (value / 100) % 1 > 0;

    const sign = this.dataset.negative === 'true' ? '-' : '';
    const symbol = this.dataset.currencySymbol;

    // The format here is from Shopify's `money_format` setting.
    // https://github.com/Shopify/theme-scripts/blob/daf34f63c916b98d836e21b5a69f6230b3bae9c5/packages/theme-currency/currency.js#L50
    const amount = hasDecimal ? '{{ amount }}' : '{{ amount_no_decimals }}';

    let currency = '';
    // we only display the currency code if the `showCode` is explicitly set to true
    if (this.dataset.showCode === 'true' ||
      // or if `showCode` wasn't set and the currency is different than 'USD'
      (this.dataset.showCode !== 'false' && this.dataset.currencyCode !== 'USD')) {
      currency = ' ' + this.dataset.currencyCode;
    }

    const format = [sign, symbol, amount, currency].join('');

    const formatted = ShopifyThemeCurrency.formatMoney(value, format);
    container.textContent = formatted;

    if (this.dataset.ariaLabelPrefix && value) {
      container.setAttribute('aria-label', [this.dataset.ariaLabelPrefix, formatted].join(' '));
      // this is a workaround for a pretty complicated a11y issue with NVDA and WCAG standards
      // while it may look strange it actually works well in VoiceOver and NVDA screen readers
      // and is allowed by the accessibility standards, see:
      // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/img_role#using_roleimg_to_confer_meaning_that_is_obscured_or_implied
      container.setAttribute('role', 'img');
    } else {
      container.removeAttribute('aria-label');
      container.removeAttribute('role');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'price-element': PriceElement;
  }
}

if (!customElements.get('price-element')) {
  customElements.define('price-element', PriceElement);
}
